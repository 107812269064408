var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RowWrap', {
    class: [{
      'is-empty': _vm.mDriverLogs.length === 0
    }],
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm.mDriverLogs.length > 0 ? _c('Row', {
          key: -1,
          staticClass: "Row-Header",
          attrs: {
            "parentHover": false,
            "items": {
              row: _vm.header
            },
            "index": -1
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, _vm._l(_vm.mDriverLogs, function (log, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "isDropdown": "",
        "hasBadge": log.hasBadge,
        "color": log.color,
        "parentHover": false,
        "isReadOnly": log.isReadOnly,
        "items": log,
        "index": i
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }