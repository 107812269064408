<template>
    <RowWrap :class="[{ 'is-empty': mDriverLogs.length === 0 }]">
        <template #header>
            <Row
                v-if="mDriverLogs.length > 0"
                :parentHover="false"
                :items="{ row: header }"
                :key="-1"
                :index="-1"
                class="Row-Header"
            />
        </template>
        <Row
            isDropdown
            v-for="(log, i) in mDriverLogs"
            :hasBadge="log.hasBadge"
            :color="log.color"
            :parentHover="false"
            :isReadOnly="log.isReadOnly"
            :items="log"
            :key="i"
            :index="i"
        />
    </RowWrap>
</template>

<script type="text/javascript">
import Row from '@/components/Row';
import { format } from 'date-fns';
import { distanceToString } from '@/lib/helper';
import RowWrap from '@/components/RowWrap.vue';

export default {
    name: 'ItemTable',
    components: {
        RowWrap,
        Row,
    },
    props: {
        driversLog: {
            type: Array,
            default: () => [],
        },
    },
    data: () => {
        return {
            activeRow: -1,
            activeBar: -1,
        };
    },
    computed: {
        header() {
            return [
                { name: 'Von', value: null },
                { name: 'Nach', value: null },
                { name: 'Start-KM', value: null },
                { name: 'End-KM', value: null },
                { name: 'Zweck', value: null },
            ];
        },
        mDriverLogs() {
            return this.formattedDriversLog(this.driversLog);
        },
    },
    methods: {
        formattedDriversLog(driverLogs) {
            const mDriverLogs = driverLogs
                .sort((a, b) => b.isVisible - a.isVisible)
                .map(log => {
                    const hasAllValues =
                        (log.from ?? false) && (log.to ?? false) && log.purpose?.value;
                    const hasCustomPurpose =
                        log.purpose?.id !== 'regular' ? log.purpose?.value : null;
                    return {
                        hasBadge: !hasAllValues,
                        color: !hasAllValues ? 'var(--color-red)' : null,
                        row: [
                            {
                                name: 'Von',
                                value: log.from,
                            },
                            {
                                name: 'Nach',
                                value: log.to,
                            },
                            {
                                name: 'Start-KM',
                                value: distanceToString(log.startKM),
                            },
                            {
                                name: 'End-KM',
                                value: distanceToString(log.endKM),
                            },
                            {
                                name: 'Zweck',
                                value: hasCustomPurpose ? '' : log.purpose?.value,
                                props:
                                    !hasAllValues || hasCustomPurpose
                                        ? {
                                              keyName: 'purpose',
                                              value: hasCustomPurpose
                                                  ? `${log.purpose?.value} - Bearbeiten`
                                                  : 'Zweck hinterlegen',
                                              variant: 'minimal',
                                              size: 'small',
                                              id: log.id,
                                              onClick: () =>
                                                  this.$emit('onLogItemClick', { ...log }),
                                          }
                                        : null,
                                component: !hasAllValues ? 'Button' : null,
                            },
                        ],
                    };
                });
            return mDriverLogs;
        },
    },
};
</script>
